
const ENV = "PRODUCTION";


// push trigger1
// Shared constants
export const HSPA_TITLE = "Hillside Street";
export const NAVIGATE_LA_LINK = "https://navigatela.lacity.org/navigatela/?search=ssi%203512700";

export const MAPBOX_TOKEN = "pk.eyJ1IjoiYXJqdW5uYWlyMTUiLCJhIjoiY2xnY3YwcWdpMDJjMTNkcm5xa3FjdWIzNCJ9.hVMks5_vDvzMgseTHYTNKg";

export const HSPA_API_BASE_LOCAL = "http://localhost:3001/";
export const HSPA_API_BASE = ENV === "PRODUCTION" ? "https://api4.hspa.info/" : HSPA_API_BASE_LOCAL;
// export const OLD_POTREE_BASE = "https://potree-bucket-dev.s3.amazonaws.com/visualizations/";
export const POTREE_BASE = "https://potree-bucket.s3.us-east-2.amazonaws.com/visualizations/";

// Application endpoints
export const GET_FILTERS = "filters";
export const GET_FILTERED_DATA = "filteredData";

// Collection endpoints
export const GET_COLLECTION_FILES = "getCollectionFiles";
export const GET_COLLECTION_DATA = "getCollection";

// Evacuation endpoints
export const GET_EVACUATION_DATA = "getEvacuation";
export const GET_SHORTENED_DATA = "shortenedData";

// Evacuation endpoints
export const GET_ACCESSIBILITY_DATA = "getAccessibility";

// Cost endpoints
export const GET_COST_DATA = "costData";
export const GET_ALL_COST_DATA = "allCostData";