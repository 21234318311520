import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Papa from "papaparse";
import tokml from "tokml";

const exportData = (data, fileName, type) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

const flattenObject = (obj, parent, res = {}) => {
  for (const key in obj) {
    const propName = parent ? `${parent}_${key}` : key;
    if (key === "coordinates" && Array.isArray(obj[key])) {
      res.geometry_coordinates = obj[key]
        .map((coord) => `[${coord.join(",")}]`)
        .join(", ");
    } else if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      key !== "coordinates"
    ) {
      flattenObject(obj[key], propName, res);
    } else if (key !== "coordinates") {
      res[propName] = obj[key];
    }
  }
  return res;
};

const processData = (data) => {
  console.log(data)
  return data.features.map((item) => {
    const flattenedItem = flattenObject(item);
    const cleanedItem = {};

    for (const key in flattenedItem) {
      if (key.startsWith("properties_")) {
        cleanedItem[key.replace("properties_", "")] = flattenedItem[key];
      } else {
        cleanedItem[key] = flattenedItem[key];
      }
    }
    return cleanedItem;
  });
};

/*
 * SplitButton Download Component
 *
 * Provides functionality to select a data format and download data
 */
export default function SplitButton({ downloadData, fileName }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = [
    fileName + " (GeoJSON)",
    fileName + " (csv)",
    fileName + " (kml)",
    // fileName + ' (Shapefile)'
  ];

  // Function to download Json data
  const exportToJson = (e) => {
    exportData(
      JSON.stringify({
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
        },
        features: downloadData,
      }),
      "data.json",
      "application/json"
    );
  };

  // Function to download CSV data
  const exportToCSV = (e) => {
    console.log(e)
    console.log("Processed Data: " + downloadData)
    const processedData = processData(downloadData);
    exportData(
      Papa.unparse(processedData),
      "data.csv",
      "text/csv;charset=utf-8;"
    );
  };

  // Function to download KML data
  const exportToKML = (e) => {
    console.log(downloadData)
    const realData = processData(downloadData)
    exportData(
      tokml({
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
        },
        features: realData,
      }),
      "data.kml",
      "application/vnd"
    );
  };

  // UNDER DEVELOPMENT: Function to download Shapefile
  const exportToShapefile = (e) => {
    let outputZippedShapefileStream;
    // convert(downloadData, outputZippedShapefileStream, options)
    exportData(outputZippedShapefileStream, "data.zip", " application/zip");
  };

  // Function to changed download format
  const handleClick = () => {
    if (selectedIndex === 0) {
      exportToJson();
    } else if (selectedIndex === 1) {
      exportToCSV();
    } else if (selectedIndex === 2) {
      exportToKML();
    } else if (selectedIndex === 3) {
      exportToShapefile();
    }
  };

  // Function to handle dropdown button
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="downloadDrop"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
